<template>
  <div>
    <!--<h4 class="media align-items-center font-weight-bold py-3 mb-4">
      <div class="media-body ml-3">
        Bienvenido, {{ $session.get("Nombres") }}!
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal">{{ fechaAhora }}</small>
        </div>
      </div>
    </h4>-->
    <div class="row">
      <div class=" col-xl-6 col-xxl-6">
        <!--begin::Engage Widget 6-->
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-body d-flex p-0">
            <div
              class="flex-grow-1 bg-danger p-12 pb-30 card-rounded flex-grow-1 bgi-no-repeat cover-personaje"
              :style="
                'background-image: url(' +
                  publicResource(
                    'media/transparencia/personajes/personaje-locales.png'
                  ) +
                  ')'
              "
            >
              <p
                class="text-inverse-danger pt-10 pb-0 font-size-h3 font-weight-bolder"
                style="max-width: 70%;"
              >
                ¡Hola {{ $session.get("Nombres") }}! <br />Gracias por estar
                aquí.
              </p>
              <p
                class="text-inverse-danger pt-10 pb-10 font-size-h5  line-height-lg"
                style="max-width: 60%;"
              >
                Utiliza los siguientes atajos para encontrar las fichas de
                observación electoral asignadas a ti.
              </p>

              <router-link
                to="/location/list/localVotacionUsuario"
                class="btn btn-warning font-weight-bold py-2 px-6 cursor-pointer"
              >
                Mis Locales
              </router-link>
              <router-link
                to="/location/list/localVotacionUsuarioTurno"
                class="btn btn-warning font-weight-bold py-2 px-6 ml-2 cursor-pointer"
              >
                Mis Turnos
              </router-link>
            </div>
          </div>
        </div>
        <!--end::Engage Widget 6-->
      </div>
      <div class=" col-xl-6 col-xxl-6">
        <!--begin::Engage Widget 6-->
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-body d-flex p-0">
            <div
              class="flex-grow-1 bg-warning p-12 pb-30 card-rounded flex-grow-1 bgi-no-repeat cover-personaje"
              :style="
                'background-image: url(' +
                  publicResource(
                    'media/transparencia/personajes/personaje-imagen.png'
                  ) +
                  ')'
              "
            >
              <p
                class="text-inverse-warning pt-10 pb-0 font-size-h3 font-weight-bolder"
                style="max-width: 70%;"
              >
                ¡Accede a la Galeria!
              </p>
              <p
                class="text-inverse-warning pt-10 pb-10 font-size-h5  line-height-lg"
                style="max-width: 60%;"
              >
                Visualiza la galeria de imágenes a traves del siguiente enlace
              </p>

              <router-link
                to="/multimedia/list/procesoElectoralMultimedia"
                class="btn btn-danger font-weight-bold py-2 px-6 cursor-pointer"
              >
                Ver imágenes
              </router-link>
            </div>
          </div>
        </div>
        <!--end::Engage Widget 6-->
      </div>
    </div>
    <hr class="container-m-nx mt-0 mb-4" />

    <div class="row">
      <div class="col-lg-4">
        <router-link
          to="/location/list/localVotacionUsuario"
          class="card card-custom card-stretch gutter-b"
        >
          <div class="card-body">
            <span class="fa fa-clipboard-check fa-3x ml-n1"></span>
            <div
              class="text-inverse-white font-weight-bolder font-size-h5 mb-2 mt-5"
            >
              Mis Locales
            </div>
            <div class="font-weight-bold text-inverse-white font-size-sm">
              Para coordinadores y remotos
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-4">
        <router-link
          to="/location/list/localVotacionUsuarioTurno"
          class="card card-custom card-stretch gutter-b"
        >
          <div class="card-body">
            <span class="fa fa-clock fa-3x ml-n1"></span>
            <div
              class="text-inverse-white font-weight-bolder font-size-h5 mb-2 mt-5"
            >
              Mis Turnos
            </div>
            <div class="font-weight-bold text-inverse-white font-size-sm">
              Para presenciales
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-4">
        <router-link
          to="/multimedia/list/procesoElectoralMultimedia"
          class="card card-custom card-stretch gutter-b"
        >
          <div class="card-body">
            <span class="fa fa-image fa-3x ml-n1"></span>
            <div
              class="text-inverse-white font-weight-bolder font-size-h5 mb-2 mt-5"
            >
              Imágenes
            </div>
            <div class="font-weight-bold text-inverse-white font-size-sm">
              Visualiza y agrega imágenes
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-4">
        <router-link
          to="/incident/list/localVotacionActosViolencia"
          class="card card-custom card-stretch gutter-b"
        >
          <div class="card-body">
            <span class="fa fa-hand-paper fa-3x ml-n1"></span>
            <div
              class="text-inverse-white font-weight-bolder font-size-h5 mb-2 mt-5"
            >
              Actos de Violencia
            </div>
            <div class="font-weight-bold text-inverse-white font-size-sm">
              Listado de incidentes registrados
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-xl-4">
        <router-link
          to="/incident/list/localVotacionIncidenteOtros"
          class="card card-custom card-stretch gutter-b"
        >
          <div class="card-body">
            <span class="fa fa-bell fa-3x ml-n1"></span>
            <div
              class="text-inverse-white font-weight-bolder font-size-h5 mb-2 mt-5"
            >
              Otros incidentes
            </div>
            <div class="font-weight-bold text-inverse-white font-size-sm">
              Listado de incidentes registrados
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-xl-4">
        <router-link
          to="/electoral/list/PropagandaElectoral"
          class="card card-custom card-stretch gutter-b"
        >
          <div class="card-body">
            <span class="fa fa-id-badge fa-3x ml-n1"></span>
            <div
              class="text-inverse-white font-weight-bolder font-size-h5 mb-2 mt-5"
            >
              Propaganda electoral
            </div>
            <div class="font-weight-bold text-inverse-white font-size-sm">
              Listado de eventos registrados
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <!--Modal AddEditProcesoElectoralMultimediaAlbun-->
    <div v-if="imagenes.mostrarModal">
      <add-edit-proceso-electoral-multimedia-album
        v-model="imagenes.mostrarModal"
        :local-votacion-id="localVotacionId"
      ></add-edit-proceso-electoral-multimedia-album>
    </div>
  </div>
</template>

<style>
.cover-personaje {
  background-position: calc(100% + -0.5rem) bottom;
  background-size: auto 100%;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .cover-personaje {
    background-position: calc(100% + -0.5rem) 15px;
    background-size: auto 140%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .cover-personaje {
    background-position: calc(100% + 0.5rem) bottom;
    background-size: auto 90%;
  }
}

@media (min-width: 1500px) {
  .cover-personaje {
    background-position: calc(100% + -0.5rem) 25px;
    background-size: auto 130%;
  }
}
</style>

<script>
import Vue from "vue";
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import addEditProcesoElectoralMultimediaAlbum from "@/components/multimedia/add-edit-proceso-electoral-multimedia-album";
import Swal from "sweetalert2";

/*intervalos de tiempo de Inactividad*/
import {
  INACTIVE_USER_TIME_THRESHOLD,
  USER_ACTIVITY_THROTTLER_TIME,
} from "@/helpers/constants.js";

export default {
  name: "Inicio",
  metaInfo: {
    title: "Inicio",
  },
  components: {
    addEditProcesoElectoralMultimediaAlbum,
  },
  data: () => ({
    moment: moment,
    fechaAhora: null,
    fechaUltimaActualizacion: null,
    temporizadorFechaActual: null,
    imagenes: {
      mostrarModal: false,
    },
    /*variables de inactividad*/
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  computed: {},
  methods: {
    /*Metodos para verificar la inactividad del usuario*/
    activateActivityTracker: function() {
      window.addEventListener("mousedown", this.userActivityThrottler);
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
      window.addEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    deactivateActivityTracker: function() {
      window.removeEventListener("mousedown", this.userActivityThrottler);
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
      window.removeEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    resetUserActivityTimeout: function() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD); /*=> INACTIVE_USER_TIME_THRESHOLD */
    },
    userActivityThrottler: function() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME); /*=> USER_ACTIVITY_THROTTLER_TIME*/
      }
    },
    inactiveUserAction: function() {
      this.isInactive = true;

      Swal.fire({
        icon: "warning",
        title: "Sesión Expirada",
        text: "Su sesión ha expirado. Por favor vuelva a iniciar sesión.",
        confirmButtonText: `Iniciar Sesión`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deactivateActivityTracker();
          clearTimeout(this.userActivityTimeout);
          clearTimeout(this.userActivityThrottlerTimeout);
          this.$router.push({ name: "login" });
        }
      });
    },
  },
  watch: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Inicio" }]);
    this.moment.locale("es");
    this.fechaAhora = moment(new Date()).format("D MMMM YYYY, h:mm:ss a");

    var vm = this;
    this.temporizadorFechaActual = setInterval(function() {
      vm.fechaAhora = moment(new Date()).format("D MMMM YYYY, h:mm:ss a");
    }, 1000);

    this.activateActivityTracker();
  },
  destoyed: function() {
    clearInterval(this.temporizadorFechaActual);
  },
};
</script>
